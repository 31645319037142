import React, { Component } from 'react';
import axios from "axios";
import '../../css/table.css';
import {formatCurrency, getResponseErrorsList} from "../../util/util";
import Select, { components }  from "react-select";
import {RestrictedArea}from "../../wrappers/RestrictedArea";
import {UserContext, UserGroup} from "../../context/User";
import {FBCabState, GameReleaseState} from "../../common/commonTypes";
import { Line } from 'rc-progress';


const TICK_INTERVAL = 80;
const UPDATE_CABS_INTERVAL = 1000;
const MIN_ADD_CABS_TIME = 5000;

class FBSettings extends Component {
    state = {
        loading: true,
        selectedGameID: -1,
        games: [],
        fbCabs: null,
        updatingCabsProgress: 0,
        updatingCabsStartTime: 0,
        updatingCabsEndTime: 0,
        updatingCabsNextUpdate: 0,
        forceUpdateCabs: false
    };

    // Fetch the list on first mount
    componentDidMount() {
        this.fetchData();
        this.timerID = setTimeout(this.tick.bind(this), TICK_INTERVAL);
    }

    componentWillUnmount() {
        clearTimeout(this.timerID);
    }

    getUser() {
        return this.context;
    }

    async fetchData() {
        this.setState({loading: true});

        let res = await axios.post('/get_fb_settings');

        if (res.data) {
            let games = res.data.games;
            this.setState({loading: false, games: games});
        }
    }

    async fetchFBCabs(gameID) {
        if (gameID === -1) return;
        try {
            let res = await axios.post('/get_fb_cabs', {game_id: gameID});

            if (this.state.selectedGameID === gameID && res.data && Array.isArray(res.data))
                this.setState({fbCabs: res.data});
        } catch (err) {
            this.setState({errors: getResponseErrorsList(err)});
        }
    }

    onGameSelected(selectedGame) {
        this.setState({selectedGameID: selectedGame.value, addNewCabsList: '', fbCabs: null, updatingCabsProgress: 0, updatingCabsEndTime: 0, forceUpdateCabs: true});
    }

    getGameByID(gameID) {
        if (!this.state.games)
            return null;
        return this.state.games.find(game => game.id === gameID);
    }

    onAddNewCabsListChanged(e) {
        this.setState({errors: '', addNewCabsList: e.target.value});
    }

    async onAddNewCabs() {
        if (!this.state.selectedGameID || !this.state.addNewCabsList || this.state.addNewCabsList === '') {
            this.setState({ errors: { common: 'Неправильный формат'} });
            return;
        }

        //if (this.state.operationInProgress) return;
        //this.setState({operationInProgress: true});
        this.setState({errors: ''});

        try {
            let addNewCabsList = this.state.addNewCabsList.split(/[\s, ]+/).filter(cab => cab.match(/^[0-9]+$/));
            if (addNewCabsList.length > 0) {
                await this.addCabs(this.state.selectedGameID, addNewCabsList);
                this.setState({addNewCabsList: ''});
                this.updateCabs();
            }
        } catch (err) {
            this.setState({errors: getResponseErrorsList(err)});
        }

        //this.setState({operationInProgress: false});
    }

    updateCabs() {
        let selectedGame = this.getGameByID(this.state.selectedGameID);
        let now = new Date().getTime();
        let approximateDuration = (selectedGame.fb_cab_add_time * 1000 > MIN_ADD_CABS_TIME) ? selectedGame.fb_cab_add_time * 1000 : MIN_ADD_CABS_TIME;
        this.setState({updatingCabsStartTime: now, updatingCabsEndTime: now + approximateDuration, updatingCabsNextUpdate: now + UPDATE_CABS_INTERVAL});
    }

    async changeCabs(gameID, method, cabs) {
        if (cabs && cabs.length > 0) {
            let res = await axios.post(method, { game_id: gameID, cabs: cabs });
            if (this.state.selectedGameID === gameID && res.data && Array.isArray(res.data))
                this.setState({fbCabs: res.data});
        }
    }
    async addCabs(gameID, cabs) {
        await this.changeCabs(gameID, '/add_fb_cabs', cabs);
    }
    async removeCabs(gameID, cabs) {
        await this.changeCabs(gameID, '/remove_fb_cabs', cabs);
    }

    async onFBCabRetryClick(fbCab,e) {
        e.preventDefault();
        this.setState({errors: ''});
        try {
            if (fbCab.state === FBCabState.FAILED_TO_ADD) {
                await this.addCabs(this.state.selectedGameID, [fbCab.fb_cab]);
                this.updateCabs();
            }
        } catch (err) {
            this.setState({errors: getResponseErrorsList(err)});
        }
    }

    async onFBCabRemoveClick(fbCab,e) {
        e.preventDefault();
        this.setState({errors: ''});
        try {
            if (fbCab.state === FBCabState.ACTIVE || fbCab.state === FBCabState.FAILED_TO_ADD || fbCab.state === FBCabState.FAILED_TO_REMOVE) {
                await this.removeCabs(this.state.selectedGameID, [fbCab.fb_cab]);
                if (fbCab.state !== FBCabState.FAILED_TO_ADD) this.updateCabs();
            }
        } catch (err) {
            this.setState({errors: getResponseErrorsList(err)});
        }
    }

    async tick() {
        let now = new Date().getTime();
        let {selectedGameID, fbCabs, updatingCabsProgress, updatingCabsNextUpdate, updatingCabsStartTime, updatingCabsEndTime, forceUpdateCabs} = this.state;
        let newUpdatingCabsProgress = (updatingCabsStartTime <= now && now <= updatingCabsEndTime)
            ? (100 * (now - updatingCabsStartTime) / (updatingCabsEndTime - updatingCabsStartTime)) : 0;
        if ( forceUpdateCabs || (now >= updatingCabsNextUpdate && (updatingCabsProgress > 0
                || (fbCabs && fbCabs.some(fbCab => [FBCabState.TO_BE_ADDED, FBCabState.TO_BE_REMOVED].some(state => fbCab.state === state))) )) )
        {
            await this.fetchFBCabs(selectedGameID);
            updatingCabsNextUpdate = new Date().getTime() + UPDATE_CABS_INTERVAL;
        }
        if (forceUpdateCabs || updatingCabsProgress !== newUpdatingCabsProgress)
            this.setState({ updatingCabsProgress: newUpdatingCabsProgress, updatingCabsNextUpdate, forceUpdateCabs: false });
        this.timerID = setTimeout(this.tick.bind(this), TICK_INTERVAL);
    }

    render() {
        const simpleColors = !this.getUser().hasPermission(UserGroup.MODERATOR);

        const { errors, updatingCabsProgress, fbCabs, selectedGameID, addNewCabsList } = this.state;
        let games = this.state.games ? this.state.games.map(game => ({
            value: game.id, label: `${game.name}`, class: GameReleaseState.getColorClass(game.released, "", simpleColors)
        }) ) : [];
        let selectedGame = this.getGameByID(selectedGameID);

        let prevTime = null;
        let fbCabsList = fbCabs ? fbCabs.reduce((fbCabsList, fbCab) => {
            if (prevTime !== fbCab.created_at) {
                fbCabsList.push('separator');
                prevTime = fbCab.created_at;
            }
            fbCabsList.push(fbCab);
            return fbCabsList;
        }, []).reverse() : null;

        const SelectedHeader = props => <components.SingleValue {...{...props, className: props.className + " " + props.data.class}}/>;
        const GameOption = props => <components.Option {...{...props, className: props.className + " " + props.data.class}}/>

        return (
            <RestrictedArea allowedTo={UserGroup.USER}>
            <div className="page">
                <h1>Настройки Facebook</h1>

                <div className="wide-form">
                    <div className="center-section">
                        <Select
                            className={"select"}
                            onChange={this.onGameSelected.bind(this)}
                            options={games}
                            components={{ Option: GameOption, SingleValue: SelectedHeader }}
                            isLoading={this.props.isLoading}
                            isDisabled={this.props.isDisabled}
                            isSearchable={true}
                            placeholder={'-- Выберите приложение --'}
                        />
                    </div>

                    { fbCabsList &&
                    <div>

                        <div className="left-align-section" style={{width: '600px', marginTop: '20px'}}>

                            <div className="caption" style={{marginTop: '10px'}}>Добавить список рекламных кабинетов для <b>{selectedGame ? selectedGame.name : '?'}</b>:</div>
                            {/*selectedGame && (selectedGame.released === GameReleaseState.RESTRICTED_99_CABS) &&
                                <div className="caption yellow">(Ограничение FB: <b>99</b>)</div>
                            */}
                            <div><textarea id="targetLink" className="wide" style={{height: '130px'}} name="targetLink" onChange={this.onAddNewCabsListChanged.bind(this)} value={addNewCabsList} /></div>
                            <div style={{marginTop: '5px'}}><button onClick={this.onAddNewCabs.bind(this)} className="common" disabled={!addNewCabsList || addNewCabsList === ''}>Добавить кабинеты</button></div>
                            { errors && errors.common &&
                            <div className="error holder"><div className="message">{errors.common}</div></div>
                            }
                            <div className="tip" style={updatingCabsProgress > 0 ? {} : {display: 'none'}}><Line percent={updatingCabsProgress} strokeWidth={0.5} trailWidth={0.5} strokeColor="#30a1d8" /><br/>
                                Обновляю кабитеты. Минутку...
                            </div>
                        </div>

                        <h3>Кабинеты для рекламы в Facebook:</h3>
                        <div className={"readOnlyCode"}>
                            {fbCabsList.map((fbCab, index)=>{
                                if (fbCab === 'separator') return <div key={index} className={'separator'}/>;
                                const doRetry = this.onFBCabRetryClick.bind(this,fbCab);
                                const doRemove = this.onFBCabRemoveClick.bind(this,fbCab);
                                return <div key={index} className={`tinyItem${ fbCab.state === FBCabState.FAILED_TO_ADD ? ' failedToAdd' : ''}`}
                                            style={(fbCab.state === FBCabState.TO_BE_REMOVED || fbCab.state === FBCabState.FAILED_TO_REMOVE) ? {opacity: 0.5} : {}}
                                >{fbCab.fb_cab}{
                                    (fbCab.state === FBCabState.TO_BE_ADDED || fbCab.state === FBCabState.TO_BE_REMOVED) && <div className="small-spinner"><div/></div>}{
                                    (fbCab.state === FBCabState.FAILED_TO_ADD) && <button className={"fbCabButton"} onClick={doRetry}><div className="smallIcon repeatSmallIcon"/></button>}{
                                    (fbCab.state === FBCabState.ACTIVE || fbCab.state === FBCabState.FAILED_TO_ADD || fbCab.state === FBCabState.FAILED_TO_REMOVE) &&
                                    <button onClick={doRemove} className={"fbCabButton"}><div className="smallIcon crossSmallIcon"/></button>}</div>;
                            })}
                        </div>
                    </div>
                    }



                </div>
            </div>
            </RestrictedArea>
        );
    }
}
FBSettings.contextType = UserContext;

export default FBSettings;
