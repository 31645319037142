import React, {useContext} from 'react';
import {UserContext} from "../context/User";
import {formatCurrency} from "../util/util";
import Link from "./Link";

let TopStats = () => {
    const user = useContext(UserContext);

    //console.log("[QQQ]", JSON.stringify(user));
    const balance = formatCurrency(user.balance);

    // TEST: / cl:{user.credit_limit}

    return (
        <div className="topstats">
            <div className="company">{user.clientName}, <span className="username">{user.login}</span></div>
            <div className="balance">Баланс: <span className={"sum" + (user.balance < 0 ? ' negative' : '')}>{balance}</span>
                <span style={{display: "inline-block"}}><Link to="/cabinet/deposit"><div className="top-up-button"/></Link></span>
            </div>
        </div>
    );
};

export default TopStats;