import React, {Component} from 'react';
//import {Link} from "react-router-dom";
import {StoreID, EventNames} from '../../common/commonTypes';
import Link from '../../widgets/Link';
import Pages from '../../widgets/Pages';
import axios from "axios";
import './route.css';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import queryString from 'query-string'
import {formatCurrency, formatDateTime, isEmpty} from "../../util/util";
import {UserContext} from "../../context/User";
import CreateNewDeeplink from "./CreateEditRoute";
import Switch from "react-switch";
import {useLocation} from "react-router-dom";
import MenuAppType from "./MenuAppType";

const GPLINK_TEMPLATE = 'https://play.google.com/store/apps/details?id={BUNDLE_ID}&url={URL}';
const APPLINK_TEMPLATE = 'https://{DOMAIN}/{ROUTE_APPLINK}';
const DEFAULT_APP_TYPE_FAV_KEY = 'app_type';
const DEFAULT_APP_TYPE = StoreID.APP_STORE;

class RouteList extends Component {
    filterTimer = null;
    state = {
        page: 1,
        isLast: false,
        loading: true,
        routes: [],
        routesWhitePageGeneratingDone: {},
        deeplinksToPush: [],
        isNamingOn: false,
        appType: DEFAULT_APP_TYPE,
        filter: '',
        gpLinksMode: false
    };

    constructor(props) {
        super(props);
        this.onUserEvent = this.onUserEvent.bind(this);  // Binding here
    }

    componentDidMount() {
        const appType = this.getUser().getFavData(DEFAULT_APP_TYPE_FAV_KEY,DEFAULT_APP_TYPE);
        const params = queryString.parse(this.props.location.search);
        this.fetchData(appType, this.state.page);
        this.setState({ deeplinksToPush: [], isNamingOn: this.getUser().getFavData('is_naming_on',false),
            appType, gpLinksMode: !!params.gplinks });
        this.getUser().registerEventListener(this.onUserEvent.bind(this));
    }

    componentWillUnmount() {
        this.getUser().unregisterEventListener(this.onUserEvent.bind(this));
        if (this.filterTimer !== null) {
            clearTimeout(this.filterTimer);
            this.filterTimer = null;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.loading && !this.state.loading) {
            return;
        }
        let params = queryString.parse(this.props.location.search);
        let page = parseInt(params.page ? params.page : 1);
        if (page < 1) page = 1;
        if (page !== prevState.page && !this.state.loading) {
            //console.log(`[QQQ] {componentDidUpdate} prevState.page:${prevState.page} newPage:${page}`);
            this.fetchData(this.state.appType,page,this.state.filter);
        }
    }

    onUserEvent(event) {
        if (event.name === EventNames.UPDATE_WHITEPAGE_GENERATING_DONE) {
            const route = this.state.routes.find(r => r.id === event.data.routeID);
            if (route) {
                this.setState({routesWhitePageGeneratingDone:
                        {...this.state.routesWhitePageGeneratingDone, [event.data.routeID]: true}});
            }
        }
    }

    getUser() {
        return this.context;
    }

    async fetchData(appType,page,filter='') {
        this.setState({loading: true/*, deeplinks: []*/});

        let res = await axios.post('/routeslist', {appType, page, filter});

        if (res.data) {
            let data = res.data;
            if (data.routes) data.routes = data.routes.map(route => ({...route,
                domains: route.domains ? route.domains.split(',') : null,
                games: route.games ? route.games.split(',').map(g => {
                    const [name, appType, weight] = g.split('|');
                    return {name, appType, weight};
                }) : null,
            }));
            this.setState({...{loading: false, page: page}, ...data});
        }
    }

    isDeeplinkSelectedForPush(deeplinkID) {
        return this.state.deeplinksToPush.find(dID => dID === deeplinkID);
    }

    onSelectDeeplinkForPush(deeplinkID) {
        this.setState({deeplinksToPush:
                this.isDeeplinkSelectedForPush(deeplinkID)
                ? this.state.deeplinksToPush.filter(dID => dID !== deeplinkID)
                : [...this.state.deeplinksToPush, deeplinkID]
        });
    }

    onNamingSwitch(value,e) {
        this.setState({isNamingOn: value});
        this.getUser().setFavData('is_naming_on',value,true);
    }

    onAppTypeSelect(value,e) {
        const appType = value;
        if (this.state.appType !== appType) {
            this.setState({appType});
            this.getUser().setFavData(DEFAULT_APP_TYPE_FAV_KEY, value, true);
            this.fetchData(appType, 1, this.state.filter);
        }
    }

    onFilterChanged(e) {
        this.setState({filter: typeof(e) === 'object' ? e.target.value : e});
        if (this.filterTimer !== null) {
            clearTimeout(this.filterTimer);
            this.filterTimer = null;
        }
        this.filterTimer = setTimeout(() => {
            this.fetchData(this.state.appType,1,this.state.filter);
        }, 500);
    }

    render() {
        //console.log(`[QQQ] {render} this.state.page: ${this.state.page}`,this.props.location.search);
        const {routes,deeplinksToPush,page,isLast,loading,total_installs,
            isNamingOn,appType,filter,gpLinksMode,routesWhitePageGeneratingDone} = this.state;

        const wideLinkDesign = appType === StoreID.APP_STORE;

        const _getDeeplink = (route) => isNamingOn ? route.naming : (!gpLinksMode ? route.deeplink
                : GPLINK_TEMPLATE.replace('{BUNDLE_ID}', route.bundle_id || '')
                                 .replace('{URL}', encodeURIComponent(route.deeplink)) );

        const _getAppLink = (route) => APPLINK_TEMPLATE.replace('{DOMAIN}',
            (route.domains && route.domains.length) > 0 ? route.domains[0] : '...')
                        .replace('{ROUTE_APPLINK}', route.applink);

        const _getRouteLink = (route) => (appType === StoreID.GOOGLE_PLAY) ? _getDeeplink(route) : _getAppLink(route);

        /////////////////////
        // routeRow
        const routeRow = route => <li key={route.id}>
            <div className="info">
                {route.name === '' ? <div className="id"/> :
                    <div className="id">{`#${route.id}`}</div>}
                <div>
                    <div className="date">{formatDateTime(route.createdAt)}</div>
                    {route.name === '' ? <div className="id-only">{`#${route.id}`}</div> :
                        <div className="name">{route.name}</div>}
                </div>
                <div className='rightIcons'>{/* [PUSH]  route.pushSupport &&
                    <button
                        className={'icon ' + (this.isDeeplinkSelectedForPush(route.id) ? 'pushPressedIcon' : 'pushIcon')}
                        data-tooltip-content="Выбрать для пуша" data-tooltip-id={'tooltipPush' + route.id}
                        onClick={this.onSelectDeeplinkForPush.bind(this, route.id)}/>
                }*/}{route.isGeneratingWhitepage && !routesWhitePageGeneratingDone[route.id] &&
                    <div className="spinner" style={{opacity: 0.6, cursor: 'pointer'}} data-tooltip-content="Генерация Witepage..."
                         data-tooltip-id={'tooltipUpdatingRoute' + route.id}><div></div></div>
                }</div>
            </div>
            { wideLinkDesign &&
                <div className="wideLinksCell cell1">
                    <div>
                        {route.domains && route.domains.map(domain => <div className={'tinyItem'} style={{display: 'inline-block'}}>
                            <i>{domain}</i>
                        </div>)}
                        {route.geo && route.geo.split(',').map(g => <div className={'tinyItem'} style={{display: 'inline-block'}}>
                            {g}
                        </div>)}
                    </div>
                    <div>
                        {route.games && route.games.map(game => <div className={'tinyItem'} style={{display: 'inline-block'}}>
                            <span className={`icon tiny apptype${game.appType}`}/><span>{game.name}<span className='gray'>{
                                game.weight < 1.0 ? (' (' + Math.round(game.weight * 10000) / 100 + '%)') : ''}</span></span>
                        </div>)}
                    </div>
                </div>
            }
            { wideLinkDesign &&
                <div className="wideLinksCell cell2">
                    <div className="deeplink">
                        <div className={'inRow' + (gpLinksMode && !isNamingOn ? ' smallText' : '')}>
                            <CopyToClipboard text={route.routeLink} onCopy={() => this.setState({copied: true})}>
                                <button type="button" className="copy-to-clipboard" data-tooltip-content="Скопировать"
                                        data-tooltip-id={'tooltip' + route.id}/>
                            </CopyToClipboard>
                            <div style={{'margin': '2px'}}>{route.routeLink}</div>
                        </div>
                    </div>
                    <div className="targetLink">
                        {route.targetLink}
                    </div>
                </div>
            }
            { !wideLinkDesign &&
            <div className="deeplink">
                {(isNamingOn && !route.naming) ?
                    <div className='tip text-gray'>Нейминг не поддерживается</div>
                    :
                    <div className={'inRow' + (gpLinksMode && !isNamingOn ? ' smallText' : '')} style={{'margin': '2px'}}>
                        <CopyToClipboard text={route.routeLink} onCopy={() => this.setState({copied: true})}>
                            <button type="button" className="copy-to-clipboard" data-tooltip-content="Скопировать"
                                    data-tooltip-id={'tooltip' + route.id}/>
                        </CopyToClipboard>
                        <div style={{'margin': '2px'}}>{route.routeLink}</div>
                    </div>
                }
            </div>
            }
            { !wideLinkDesign &&
            <div className="targetLink">
                {route.targetLink}
            </div>
            }
            <div className="edit">
                <Link to={`/editflow/?id=${route.id}`} data-tooltip-delay-show={100} data-tooltip-content="Редактировать"
                      data-tooltip-id={'tooltipEdit' + route.id}>
                    <button type="button" className="edit"/>
                </Link>
            </div>
            <div className="stats">
                <Link to={`/stats/?id=${route.id}`} data-tooltip-content="Статистика"
                      data-tooltip-id={'tooltip' + route.id}>
                    <div
                        className={route.installCount === 0 ? 'zero' : ''}>{route.installCount}</div>
                    <button type="button" className="button-stats"/>
                </Link>
            </div>
            <ReactTooltip id={'tooltipPush' + route.id} type="light"
                          effect="solid"/>
            <ReactTooltip id={'tooltip' + route.id} type="light" effect="solid"/>
            <ReactTooltip id={'tooltipEdit' + route.id} type="light" effect="solid"/>
            <ReactTooltip id={'tooltipUpdatingRoute' + route.id} effect="solid"/>
        </li>;
        // ~~ routeRow
        /////////////////////

        //console.log('[QQQ]',routes[0]);
        return (
            <div className="page deeplinks">
                <h1>Потоки</h1>
                <MenuAppType activeAppType={appType} onSelect={this.onAppTypeSelect.bind(this)}/>

                <div className="section">
                    <div className="inRow" style={{justifyContent: 'flex-start'}}>
                        <div className='headerContainer'><Link to={`/newflow?at=${appType}`}><button className="common">Создать</button></Link></div>
                        {/*[NAMING]*/ appType === StoreID.GOOGLE_PLAY && this.getUser().isNamingAvailable() &&
                            <div className='headerContainer inCol'>
                                <label className='clickable smallerText text-blue' htmlFor="namingSwitch">Нейминг</label>
                                <Switch
                                    checked={isNamingOn}
                                    onChange={this.onNamingSwitch.bind(this)}
                                    onColor="#c4b6a0"
                                    onHandleColor="#cb9e63"
                                    handleDiameter={24}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    height={18}
                                    width={40}
                                    className="react-switch"
                                    id="namingSwitch"
                                />
                            </div>
                        }
                        {/*[PUSH]*/ !isEmpty(deeplinksToPush) > 0 &&
                        <div className='headerContainer'><Link to={{pathname: '/newpush', deeplinks: deeplinksToPush}}>
                            <button className={"green inRow"}>
                                <div className="icon pushPressedIcon"/>
                                <div className="inCol">
                                    <div>Пушнуть</div>
                                    <div className="smallText">выбрано: {deeplinksToPush.length}</div>
                                </div>
                            </button>
                        </Link></div>
                        }
                    </div>
                    <div className='inRow rightAlign' style={{width: '320px'}}>
                        <div className='headerContainer inRow'>
                            <input id="filter" name="filter" type="text" value={filter} placeholder='Фильтр...' onChange={this.onFilterChanged.bind(this)} />
                            {filter && <div className='holder'>
                                <button onClick={this.onFilterChanged.bind(this,'')} className={'listSmallButton'} style={{left: '-28px', top: '-10px'}}>
                                    <div className="small2Icon crossSmall2Icon"/>
                                </button>
                            </div>}
                        </div>
                        <div className='headerContainer inRow'>
                            <div className='smallerText' style={{whiteSpace: 'nowrap'}}>Всего инсталлов:</div>
                            <div><b>{total_installs}</b></div>
                        </div>
                    </div>
                </div>

                <ul>
                {routes.map(route => {
                    if (!loading) {
                        route.routeLink = _getRouteLink(route);
                    }
                    return routeRow(route);
                })}
                </ul>
                <div className="footer">
                    <div style={{width: '120px'}}/>
                    <Pages to="/" enabled={!loading} current={page} isLast={isLast}/>
                    <div style={{width: '120px'}}/>
                </div>
            </div>
        );
    }
}
RouteList.contextType = UserContext;

export default props => <RouteList location={useLocation()} {...props}/>;