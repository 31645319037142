

module.exports = {
    formatCurrency(sum,fractionDigits=2,currencySign='$') {
        if (typeof sum === 'undefined') {
            throw new Error('[formatCurrency] Undefined sum');
        }
        if (typeof sum === 'number') {
            return sum >= 0
                ? `$${sum.toFixed(fractionDigits)}`
                : `-$${(-sum).toFixed(fractionDigits)}`;
        }
        if (typeof sum !== 'string') {
            return '?';
        }
        if (sum.length > 3 && sum.substring(sum.length-1) === '0') {
            let comma = sum.lastIndexOf('.');
            if (comma === -1) comma = sum.lastIndexOf(',');
            if (comma >= 0) {
                while ((sum.length > comma + 3) && sum.substring(sum.length-1) === '0') {
                    sum = sum.substring(0, sum.length - 1);
                }
            }
        }
        return sum.substring(0,1) !== '-'
            ? `${currencySign}${sum}`
            : `-${currencySign}${sum.substring(1)}`;
    },

    formatPercent(rate,fractionDigits=0) {
        if (typeof rate !== 'number') {
            throw new Error('[formatPercent] unexpected data type');
        }
        return `${(rate*100).toFixed(fractionDigits)}%`;
    },

    formatDateTime: dateTimeStr => new Date(Date.parse(dateTimeStr)).toLocaleString().replace(',',''),

    formatClientName(client,withContactPerson=true) {
        if (!client || !client.name)
            return '-';
        return client.name + ((withContactPerson && client.contact_person && client.contact_person !== '') ? ` (${client.contact_person})` : '');
    },

    randomInt(min, max) {
        let rand = min + Math.random() * (max + 1 - min);
        return Math.floor(rand);
    },

    pick(obj, keys) {
        if (!obj) {
            return {};
        }

        keys = Array.isArray(keys) ? keys : [keys];
        let len = keys.length;
        let o = {};

        for (let i = 0; i < len; i++) {
            o[keys[i]] = obj[keys[i]];
        }
        return o;
    },

    isEmpty(value) {
        if (typeof value === 'undefined')
            return true;
        if (typeof value === 'object')
            return !value || Object.entries(value).length === 0;
        if (typeof value === 'string')
            return value === '';
        throw new Error("[isEmpty] Unrecognized type");
    },

    sortByStringField(arr,field) {
        let sortOrder = 1;

        if(field[0] === "-") {
            sortOrder = -1;
            field = field.substr(1);
        }

        return arr.sort( (sortOrder === 1) ? (a,b) => a[field].localeCompare(b[field])
                                           : (a,b) => b[field].localeCompare(a[field]));
    },

    sortByNumberField(arr,field) {
        let sortOrder = 1;

        if(field[0] === "-") {
            sortOrder = -1;
            field = field.substring(1);
        }

        return arr.sort( (sortOrder === 1) ? (a,b) => (a[field] > b[field])
                                           : (a,b) => (b[field] > a[field]));
    },

    getTimeDifferenceDesc(dateDiff) {
        let totalMinLeft = parseInt(Math.floor(dateDiff / 60000));
        let minLeft = totalMinLeft % 60;
        let hoursLeft = (totalMinLeft - minLeft) / 60;
        if (hoursLeft === 0) {
            hoursLeft = '';
        } else if (hoursLeft === 1) {
            hoursLeft += ' час';
        } else if (hoursLeft <= 4) {
            hoursLeft += ' часа';
        } else {
            hoursLeft += ' часов';
        }
        if (minLeft === 1) {
            minLeft += ' минуту';
        } else if (minLeft <= 4) {
            minLeft += ' минуты';
        } else {
            minLeft += ' минут';
        }
        return (hoursLeft + ' ' + minLeft).trim();
    },

    encodeUTF8(s) {
        return unescape(encodeURIComponent(s));
    },

    decodeUTF8(s) {
        return decodeURIComponent(escape(s));
    },

    dateToMysqlUTCDateTime(date) {
        return date.toISOString().slice(0, 19).replace('T', ' ');
    },

};