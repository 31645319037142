import React, { Component } from 'react';
import axios from "axios";
import {RestrictedArea}from "../../wrappers/RestrictedArea";
import {UserGroup} from "../../context/User";
import {formatCurrency, formatDateTime} from "../../util/util";
import './cabinet.css';
import Pages from "../../widgets/Pages";
import BasePage from "../base/BasePage";

class Finances extends BasePage {
    state = {
        loadingHistory: true,
        page: 1
    };

    componentDidMount() {
        this.fetchHistory(this.state.page);
    }

    async fetchHistory(page) {
        this.setState({loadingHistory: true});

        let res = await axios.post('/get_finance_history', {page: page});

        if (res.data && Array.isArray(res.data.financeHistory))
            this.setState({loadingHistory: false, page: page, isLast: res.data.isLast, financeHistory: res.data.financeHistory});
    }

    renderPage() {
        const { loadingHistory, financeData, financeHistory, page, isLast} = this.state;

        return (
            <RestrictedArea allowedTo={UserGroup.SUPERUSER}>
            <div className="page">
                <h1>Финансовая история</h1>

                <div style={{marginTop: '30px'}}>
                    <section className="table-data compact">
                        <header>
                            <div className="col" style={{'flexBasis':'18%'}}>Время</div>
                            <div className="col">Пользователь</div>
                            <div className="col">Сумма</div>
                            <div className="col" style={{'flexBasis':'28%'}}>Описание</div>
                        </header>
                        {financeHistory && financeHistory.map((hist,i) => (
                            <div key={i} className="row">
                                <div className="col smallText" style={{'flexBasis':'18%'}}>{formatDateTime(hist.time)}</div>
                                <div className="col smallText">{hist.user_name}</div>
                                <div className="col">{formatCurrency(hist.amount)}</div>
                                <div className="col smallerText" style={{'flexBasis':'28%'}}>{hist.comment}</div>
                            </div>
                        ))}
                    </section>
                    <div className="footer">
                        <Pages style={{margin: '0 auto'}} handler={this.fetchHistory.bind(this)} enabled={!loadingHistory} current={page} isLast={isLast}/>
                    </div>
                </div>

            </div>
            </RestrictedArea>
        );
    }
}



export default Finances;
