import React, { Component } from 'react';
import axios from "axios";
import '../../css/table.css';
import {formatCurrency} from "../../util/util";
import {GameReleaseState} from "../../common/commonTypes";
import Link from "../../widgets/Link";
import queryString from "query-string";
import {UserGroup} from "../../context/User";
import {RestrictedArea}from "../../wrappers/RestrictedArea";
import {useLocation} from "react-router-dom";

class AdminSingleGameStats extends Component {
    // Initialize the state
    constructor(props){
        super(props);
        this.state = {
            game_id: 0,
            data: []
        }
    }

    componentDidMount() {
        let params = queryString.parse(this.props.location.search);
        let game_id = parseInt(params.id ? params.id : 0);
        if (game_id !== 0) {
            this.fetchData(game_id);
        }
    }

    async fetchData(game_id) {
        this.setState({loading: true});

        let res = await axios.post('/admin_game_install_stats', {game_id: game_id});

        if (res.data)
            this.setState({loading: false, game_id: game_id, data: res.data});
    }

    render() {
        const { data } = this.state;
        const rowDefs = data ? [
            { name: 'По диплинкам', data: data.deeplinkInstalls },
            { name: 'Органика', data: data.organicInstalls }
        ] : [];

        let install_count = (count) => {
            if (isNaN(count))
                return <div className="gray">0</div>;
            if (count >= 1000)
                return <div className="blue">{count}</div>
            if (count >= 300)
                return <div className="green">{count}</div>
            if (count > 0)
                return <div className="weakgreen">{count}</div>
            return <div className="gray">{count}</div>;
        };

        let cl_install_count = (row, field) => {
            let dl_count = parseInt(isNaN(row['dl_' + field]) ? 0 : row['dl_' + field]);
            let org_count = parseInt(isNaN(row['org_' + field]) ? 0 : row['org_' + field]);
            let count = dl_count + org_count;
            let _class = 'gray';
            if (count >= 1000) _class = 'blue';
            else if (count >= 300) _class = 'green';
            else if (count >= 0) _class = 'weakgreen';
            let org_count_div = (org_count > 0) ? <div className="verySmallText">о: {org_count}</div> : null;
            return <div className={_class}>{dl_count}{org_count_div}</div>;
        };

        let game_name = (name,released) => {
            if (released === GameReleaseState.BANNED_ON_MARKET || released === GameReleaseState.NOT_RELEASED || released === GameReleaseState.WAIT_FOR_RELEASE)
                return <div className="red">{name}</div>;
            if (released === GameReleaseState.BANNED_BY_FB)
                return <div className="yellow">{name}</div>;
            return name;
        };

        return (
            <RestrictedArea allowedTo={UserGroup.PLATFORM_ADMIN}>
            <div className="page">
                {<div className="header" style={{marginBottom: '40px'}}>
                    <div/>
                    <Link to="/admin/gamestats"><button className="common text-and-icon secondary"><span className="icon"><i className="arrow left"/></span><span>Все прилки</span></button></Link>
                    <div/>
                </div>}

                <h1>Статистика {data && data.deeplinkInstalls && game_name(data.deeplinkInstalls.name, data.deeplinkInstalls.released)}</h1>

                <h2>Статистика инсталлов</h2>

                <section className="table-data compact wide90">
                    <header>
                        <div className="col" style={{flexBasis: '10%'}}>Тип инсталлов</div>
                        <div className="col">За 24 часа</div>
                        <div className="col">За сегодня</div>
                        <div className="col">За вчера</div>
                        <div className="col">За позавчера</div>
                        <div className="col">Всего</div>
                    </header>

                {rowDefs.map(rowDef => {
                    const row = rowDef.data;
                    return <div>
                        {row &&
                        <div className="row">
                            <div className="col" style={{flexBasis: '10%'}}>
                                <div className={row.released ? '' : 'gray'}><Link className="soft"
                                                                                  to="/admin_game_install_stats">{rowDef.name}</Link>
                                </div>
                            </div>
                            <div className="col">
                                <div
                                    className={(!row.released || row.total_installs > 0) ? 'smallerText' : 'verySmallText'}>{
                                    (row.released === GameReleaseState.NOT_RELEASED || row.total_installs > 0)
                                        ? install_count(row._24h_change) : 'Прилка готова и ждет тестовых инсталлов!'
                                }</div>
                            </div>
                            <div className="col">
                                <div
                                    className="smallerText">{install_count(row.today_change)}</div>
                            </div>
                            <div className="col">
                                <div
                                    className="smallerText">{install_count(row.day_ago_change)}</div>
                            </div>
                            <div className="col">
                                <div
                                    className="smallerText">{install_count(row._2days_ago_change)}</div>
                            </div>
                            <div className="col">
                                <div className="smallText">{row.total_installs}</div>
                            </div>
                        </div>
                        }
                    </div>
                })}
                </section>

                <h2>Статистика по клиентам</h2>

                <section className="table-data mid-width smallText">
                    <header>
                        <div className="col" style={{ textAlign: 'right', padding: '0 20px 0 10px' }}>
                            <div>Пользователь</div>
                            <div className="verySmallText">(клиент)</div>
                        </div>
                        <div className="col" >За 24 часа</div>
                        <div className="col" >За сегодня</div>
                        <div className="col" >За вчера</div>
                        <div className="col" >За позавчера</div>
                        <div className="col" >Всего</div>
                    </header>
                    {data && data.clientStats && data.clientStats.map(row =>
                        <div className="row">
                            <div className="col" style={{ textAlign: 'right', padding: '0 20px 0 10px' }}>
                                <div>{row.user}</div>
                                <div className="verySmallText">{row.client}</div>
                            </div>
                            <div className="col">{cl_install_count(row,'24h_change')}</div>
                            <div className="col">{cl_install_count(row,'today_change')}</div>
                            <div className="col">{cl_install_count(row,'day_ago_change')}</div>
                            <div className="col">{cl_install_count(row,'2days_ago_change')}</div>
                            <div className="col">{cl_install_count(row,'total_install')}</div>
                        </div>
                    )}
                </section>

                <h2>Статистика по странам</h2>

                <section className="table-data compact mid-width smallText">
                    <header>
                        <div className="col" style={{ textAlign: 'right',padding: '0 20px 0 10px' }}>Гео</div>
                        <div className="col" >За 24 часа</div>
                        <div className="col" >За сегодня</div>
                        <div className="col" >За вчера</div>
                        <div className="col" >За позавчера</div>
                        <div className="col" >Всего</div>
                    </header>
                    {data && data.geoStats && data.geoStats.map(row =>
                        <div className="row">
                            <div className="col" style={{ textAlign: 'right',padding: '0 20px 0 10px' }}>
                                <div>{row.country}</div>
                            </div>
                            <div className="col">
                                <div>{cl_install_count(row,'24h')}</div>
                            </div>
                            <div className="col">
                                <div>{cl_install_count(row,'today')}</div>
                            </div>
                            <div className="col">
                                <div>{cl_install_count(row,'day_ago')}</div>
                            </div>
                            <div className="col">
                                <div>{cl_install_count(row,'2days_ago')}</div>
                            </div>
                            <div className="col">
                                <div>{cl_install_count(row,'total')}</div>
                            </div>
                        </div>
                    )}
                </section>

                <div className="footer" style={{marginTop: '40px'}}>
                    <div/>
                    <Link to="/admin/gamestats"><button className="common text-and-icon secondary"><span className="icon"><i className="arrow left"/></span><span>Все прилки</span></button></Link>
                    <div/>
                </div>
            </div>
            </RestrictedArea>
        );
    }
}


export default props => <AdminSingleGameStats location={useLocation()} {...props}/>;