import React, { Component } from 'react';
import axios from "axios";
import {RestrictedArea}from "../../wrappers/RestrictedArea";
import {UserGroup} from "../../context/User";
import SelectUser from "../../widgets/admin/SelectUser";
import {formatClientName, getResponseError, getResponseErrorsList, pick} from "../../util/util";
import Select from "react-select";
import BasePage from "../base/BasePage";
import {FBCabState, GameReleaseState, OrganicsState, StoreID} from "../../common/commonTypes";

const SAVE_GAME_SETTINGS_FIELDS = ['bundle_id', 'fb_app_id', 'fb_secret_key', 'fb_admin_id', 'fb_share_id',
    'appsf_dev_key', 'naming_support', 'fb_events_on', 'pushexpress_on', 'extra_geo_opened', 'wv_useragent',
    'platform_id', 'store_id'];

const SUCCESS_SAVE_MSG = 'Сохранено!';

class ManageGames extends BasePage {
    state = {
        loading: true,
        loadingPerm: false,
        selectedUserID: -1,
        selectedGameID: -1,
        selectedGameUsers: null,
        platforms: [],
        stores: StoreID.getValues()
    };

    componentDidMount() {
        this.fetchData();
        this.fetchGamesEmptyBundleID();
    }

    async fetchData(/*data = ['users','games']*/) {
        this.setState({loading: true});

        let res = await axios.post('/managegames_getdata'/*, {data: data}*/);

        if (res.data) {
            let games = res.data.games;
            games.forEach(g => {
                g.key_hashes = (g.key_hashes && g.key_hashes !== "") ? JSON.parse(g.key_hashes) : null;
            });
            this.setState({loading: false, users: res.data.users, games: games,
                platforms: res.data.platforms});
        }
    }

    async fetchGamesEmptyBundleID() {
        let res = await axios.post('/managegames_getgamesemptybundleid');
        if (res.data) {
            this.setState({ gamesEmptyBundleID: res.data.gamesEmptyBundleID });
        }
    }

    async fetchPermissions(userID) {
        if (userID === -1) {
            this.setState({gamePerm: []});
            return;
        }

        this.setState({loadingPerm: true});

        try {
            let res = await axios.post('/managegames_getpermissions', {user_id: userID});

            if (res.data && Array.isArray(res.data))
                this.setState({loadingPerm: false, gamePerm: res.data});
        } catch (err) {
            this.setState({loadingPerm: false, errors: getResponseErrorsList(err)});
        }
    }

    async fetchGameStats(gameID) {
        if (gameID === -1) {
            return;
        }

        try {
            let res = await axios.post('/managegames_getgamestats', {game_id: gameID});

            if (res.data) {
                this.setState({
                    selectedGameUsers: res.data.gameUsers,
                    selectedGameStats: res.data.gameStats,
                    selectedGameLogsState: res.data.gameLogsState,
                    selectedGameFCMState: res.data.gameFCMState,
                    selectedGameNamingState: res.data.gameNamingState,
                });
            }
        } catch (err) {
            this.setState({errors: getResponseErrorsList(err)});
        }
    }

    getUserByID(userID) {
        if (!this.state.users || !userID || userID===-1)
            return null;
        return this.state.users.find(user => user.user_id === userID);
    }

    getGameByID(gameID) {
        if (!this.state.games || !gameID || gameID===-1)
            return null;
        return this.state.games.find(game => game.id === gameID);
    }

    haveGamePerm(gameID) {
        if (!this.state.gamePerm)
            return false;

        return this.state.gamePerm.some(gamePerm => gamePerm.game_id === gameID);
    }

    canHaveGamePerm(game) {
        const user = this.getUserByID(this.state.selectedUserID);
        if (!user || !game)
            return false;

        const gameRegisteredTime = new Date(game.created_at);
        const now = new Date();
        const twoMonthsAgo = new Date(now.setMonth(now.getMonth() - 2));
        if (game.released === GameReleaseState.NOT_RELEASED && gameRegisteredTime < twoMonthsAgo)
            return false;

        if (UserGroup.hasPermission(user.group, UserGroup.DEVELOPER))
            return true;

        if (game.released === GameReleaseState.NOT_RELEASED)
            return false;

        return user.platform_id === game.platform_id;
    }

    async changePerm(gameID, newValue) {
        if (this.state.selectedUserID === -1)
            return;

        this.setState({loadingPerm: true});

        try {
            let res = await axios.post('/managegames_changeperm', {user_id: this.state.selectedUserID, game_id: gameID, new_value: newValue });

            if (res.data && Array.isArray(res.data))
                this.setState({loadingPerm: false, gamePerm: res.data});
        } catch (err) {
            this.setState({loadingPerm: false, errors: getResponseErrorsList(err)});
        }
    }

    onUserSelected(userID) {
        this.setState({ errors: '', selectedUserID: userID });

        this.fetchPermissions(userID);
    }

    getError(param) {
        if (this.state.errors && param in this.state.errors)
            return this.state.errors[param];
        return "";
    }

    onGameSelected(selectedGameVal) {
        let newState = {errors: {}, gs_execMsg: null, grs_execMsg: null, selectedGameID: selectedGameVal.value,
            selectedGameSha1Keys: '', selectedGameUsers: null};
        let selectedGame = this.getGameByID(newState.selectedGameID);
        if (selectedGame) {
            newState = {...newState, ...pick(selectedGame, SAVE_GAME_SETTINGS_FIELDS)};
        }

        this.setState(newState);
        if (selectedGame) {
            this.fetchGameStats(selectedGameVal.value);
        }
    }

    onSha1KeysChanged(e) {
        this.setState({errors: '', selectedGameSha1Keys: e.target.value});
    }

    onKeyHashesSet() {
        if (!this.state.selectedGameSha1Keys || this.state.selectedGameSha1Keys === '') {
            this.setState({ errors: { common: 'Wrong keys format'} });
        }

        try {
            let sha1Keys = this.state.selectedGameSha1Keys.split('\n');
            let keyHashes = [];
            sha1Keys.forEach(sha1Key => {
                sha1Key = sha1Key.replace('SHA1:', '').split(' ').join('');
                if (sha1Key !== '') {
                    let digits = sha1Key.split(':');
                    if (digits.length !== 20 || digits.filter(digit => digit.length !== 2).length > 0)
                        throw new Error();
                    keyHashes.push(Buffer.from(digits.join(''), "hex").toString('base64'));
                }
            });
            this.setNewKeyHashes(this.state.selectedGameID, keyHashes);
        } catch (e) {
            this.setState({ errors: { common: 'Wrong keys format'} });
        }
    }

    async setNewKeyHashes(gameID, newKeyHashes) {
        try {
            if (newKeyHashes) {
                await axios.post('/managegames_setkeyhashes', {
                    game_id: gameID,
                    key_hashes: JSON.stringify(newKeyHashes)
                });
                this.fetchData(['games']);
            }
        } catch (err) {
            this.setState({errors: getResponseErrorsList(err)});
        }
    }


    async onSubmitGameSettings(event) {
        event.preventDefault();
        if (!this.gs_busy) {
            try {
                this.gs_busy = true;
                this.setState({gs_executing: true});
                await axios.post('/managegames_save', {game_id: this.state.selectedGameID, ...pick(this.state, SAVE_GAME_SETTINGS_FIELDS)} );
                this.setState({gs_executing: false, gs_execMsg: SUCCESS_SAVE_MSG, errors: {}});
                await this.fetchData(['games']);
                await this.fetchGameStats(this.state.selectedGameID);
                setTimeout(this.setState.bind(this,{gs_execMsg: null}), 1000);
            } catch (err) {
                this.setState({...{gs_executing: false},
                    ...(err.response && err.response.data && Array.isArray(err.response.data.errors)) ?
                        {errors: getResponseErrorsList(err), gs_execMsg: 'Ошибка'}
                        : {gs_execMsg: getResponseError(err)} });
            }
            this.gs_busy = false;
        }
    }

    async onSubmitGameReleaseState(gameReleaseState) {
        try {
            await axios.post('/managegames_setreleasestate', {game_id: this.state.selectedGameID, released: gameReleaseState} );
            this.setState({errors: {}});
            await this.fetchData(['games']);
            await this.fetchGameStats(this.state.selectedGameID);
            this.setState({grs_execMsg: SUCCESS_SAVE_MSG, errors: {}});
            setTimeout(this.setState.bind(this,{grs_execMsg: null}), 1000);
        } catch (err) {
            this.setState({grs_execMsg: getResponseError(err)});
        }
    }

    renderPage() {
        const { errors, loading, loadingPerm, users, selectedUserID, selectedGameID, selectedGameSha1Keys,
            selectedGameUsers, selectedGameStats, selectedGameLogsState, selectedGameFCMState, selectedGameNamingState,
            gs_executing, gamesEmptyBundleID, platforms, stores } = this.state;

        let selectedGame = this.getGameByID(selectedGameID);
        const selectedUser =  this.getUserByID(selectedUserID);
        let games = this.state.games ? this.state.games.map(game => ({value: game.id, label: `${game.name}`}) ) : [];
        const bundleIDCaption = parseInt(this.state.store_id) === StoreID.GOOGLE_PLAY ? 'Bundle ID' : 'App ID';

        //const _haveGamePerm = this.haveGamePerm;
        const gamesCanBeShared = this.state.games ? this.state.games.filter( game => (this.haveGamePerm(game.id)
            || game.released !== GameReleaseState.BANNED_ON_MARKET) ) : [];

        const valueForField = (fieldName) => {
            if (fieldName === 'fb_share_id') return this.state[fieldName] ? this.state[fieldName] : '';
            return this.state[fieldName];
        };

        const inputField = (fieldName,description,tip,optional=true,extraClass) => (
            <li className="field" key={fieldName}>
                <div><label htmlFor={fieldName}>{description}</label>
                    <div className={"tip"}>{tip}</div>
                </div>
                <div><input className={extraClass} id={fieldName} name={fieldName} type="text"
                            onChange={this.onFieldChanged.bind(this,fieldName)}
                            required={!optional} value={valueForField(fieldName)}/>
                    {this.renderError(fieldName)}
                </div>
            </li>
        );

        const inputFieldEx = (fieldName,description,extraClass) =>
            inputField(fieldName,description,'',true,extraClass);

        const roField = (fieldName, description, tip, valueStyle) => (
            <li className="field smaller" key={fieldName}>
                <div><label className='smallerText'>{description}</label>
                    <div className={"tip"}>{tip}</div>
                </div>
                <div className={(valueStyle && 'className' in valueStyle) ? valueStyle.className : ''}
                     style={(valueStyle && !('className' in valueStyle)) ? valueStyle : {}}>{this.state[fieldName]}</div>
            </li>
        );

        const checkboxField = (fieldName,description) => {
            let value = this.state[fieldName];
            return (<li className="inCol" key={fieldName}>
                <div className="inRow"><input id={fieldName} name={fieldName} type="checkbox"
                                              onChange={this.onFieldChanged.bind(this)}
                                              checked={value}/>

                    <label htmlFor={fieldName} className='clickable'>{description}</label>
                </div>
                {this.renderError(fieldName)}
            </li>);
        };

        const outputStateInfo = (stateInfo) => {
            let style = {};
            if (stateInfo.startsWith('BAD')) {
                style = {fontWeight: 'bold', color: 'red'};
                stateInfo = stateInfo.replace(/BAD:[\s]*/i, "");
            } else if (stateInfo.startsWith('WARN')) {
                style = {fontWeight: 'bold', color: '#CFA500'};
                stateInfo = stateInfo.replace(/WARN:[\s]*/i, "");
            } else if (stateInfo.startsWith('OK')) {
                style = {color: '#62a557'};
            }
            return (<span style={style}>{stateInfo}</span>);
        }

        const selectField = (fieldName, description, tip, dataValues) => (
            <li className="field">
                <div><label htmlFor={fieldName}>{description}</label>
                    <div className={"tip"}>{tip}</div>
                </div>
                <div>
                    <select value={this.state[fieldName]}
                            onChange={this.onFieldChanged.bind(this,fieldName)}>
                        {dataValues.map(v => <option key={v.id} value={v.id}>{v.name}</option> )}
                    </select>
                    {this.renderError(fieldName)}
                </div>
            </li>
        );

        const releaseStates = [
            { state: GameReleaseState.RELEASED, header: "Рабочая", class: "grs-normal" },
            { state: GameReleaseState.IN_DEVELOPMENT, header: "В разработке", class: "grs-dev" },
            { state: GameReleaseState.RESTRICTED_99_CABS, header: "Лимит 99 каб.", class: "grs-fb99" },
            { state: GameReleaseState.BANNED_BY_FB, header: "Бан FB", class: "grs-fbban" },
            { state: GameReleaseState.NOT_RELEASED, header: "Не выпущена", class: "grs-nr" },
            { state: GameReleaseState.WAIT_FOR_RELEASE, header: "Ожидаем выпуска", class: "grs-w" },
            { state: GameReleaseState.BANNED_ON_MARKET, header: "Бан", class: "grs-ban" },
        ];

        return (
            <RestrictedArea allowedTo={UserGroup.PLATFORM_ADMIN}>
            <div className="page manage-games">
                <h1>Управление приложениями</h1>

                <div className="form">
                    <h2 style={{marginTop: '30px'}}>Права пользователей на игры</h2>

                    <SelectUser
                         users={users}
                         onChange={this.onUserSelected.bind(this)}
                         isLoading={loading}
                         isDisabled={loadingPerm}
                    />

                    { !errors && !loading && selectedUserID !== -1 &&
                        <div>
                            <h3>Доступные игры:</h3>
                            <ul>
                            {gamesCanBeShared.map(game => {
                                const canHavePerm = this.canHaveGamePerm(game);
                                const havePerm = this.haveGamePerm(game.id);
                                return (canHavePerm || havePerm) ? (
                                    <li key={game.id}>
                                        <input
                                            type="checkbox"
                                            checked={havePerm}
                                            disabled={loadingPerm}
                                            onChange={() => {this.changePerm(game.id, !havePerm)}} />
                                        <label className='clickable' onClick={() => {this.changePerm(game.id, !havePerm)}}>
                                            {game.id}: {game.name}
                                        </label>
                                    </li>
                                ) : null;
                            })}
                            </ul>
                        </div>
                     }


                    <RestrictedArea allowedTo={UserGroup.MODERATOR}>
                    <div style={{marginTop: '60px'}}>
                    <h2>Настройки игр</h2>


                    {gamesEmptyBundleID &&
                    <div style={{margin: '20px'}}>
                        <h3>Игры без Bundle ID:</h3>
                        <div className={"readOnlyCode"}>
                            {gamesEmptyBundleID.map( (game, index) =>
                                <button key={index} className='tinyItem'
                                        onClick={this.onGameSelected.bind(this,{value:game.id})}>{game.name}</button>
                            )}
                        </div>
                    </div>
                    }

                    <Select
                        className="select"
                        onChange={this.onGameSelected.bind(this)}
                        options={games}
                        isLoading={this.props.isLoading}
                        isDisabled={this.props.isDisabled}
                        isSearchable={true}
                        placeholder={'-- Выберите игру --'}
                        value={selectedGame ? {value: selectedGame.id, label: selectedGame.name} : null}
                    />

                    {selectedGameID !== -1 &&
                    <div>
                        {selectedGameStats &&
                        <div><h3>Всего инсталлов: <b style={{fontStyle: 'normal'}}>{selectedGameStats.installCount}</b></h3></div>
                        }

                        {selectedGameLogsState &&
                        <div><h3>Готовность к инсталлам (состояние логов): {outputStateInfo(selectedGameLogsState)}</h3></div>
                        }

                        {selectedGameFCMState &&
                        <div><h3>Готовность к пушам (состояние FCM): {outputStateInfo(selectedGameFCMState)}</h3></div>
                        }

                        {selectedGameNamingState &&
                        <div><h3>Готовность к неймингу: {outputStateInfo(selectedGameNamingState)}</h3></div>
                        }

                        <form className={'thin'}>
                            <div className="gameReleaseStatus">
                                {releaseStates.map(s => {
                                    const active = selectedGame && (selectedGame.released === s.state);
                                    return (
                                        <div key={s.state} className={s.class + (active ? " active" : "")}
                                             onClick={this.onSubmitGameReleaseState.bind(this, s.state)}>
                                            {active && <div className={'icon checkIcon'}/>} {s.header}</div>
                                    );
                                })}
                            </div>
                            <div>
                                {this.state.grs_execMsg && <div className="tinyTip holder2">
                                    <span className={this.state.grs_execMsg === SUCCESS_SAVE_MSG ? "green" : ""}>
                                        {this.state.grs_execMsg}</span>
                                </div>}
                            </div>
                        </form>

                        { selectedGameUsers &&
                        <div>
                            <h3>Игра доступна пользователям:</h3>
                            <div className='readOnlyCode'>
                                {selectedGameUsers.map((gameUser,index)=>{
                                    return (
                                        <div key={index} className="tinyItem">{gameUser.user} ({gameUser.clientName})</div>
                                    );
                                })}
                            </div>
                        </div>
                        }

                        {selectedGame.key_hashes &&
                        <div>
                        <h3>Ключи приложения:</h3>
                        <div className='field'>
                            <div className="caption">Хеши ключей к установке для <b>{selectedGame ? selectedGame.name : '?'}</b> в Facebook:</div>
                            <div className='readOnlyCode hashList'>
                                {selectedGame.key_hashes.map(keyHash=>{
                                    return (
                                        <div key={keyHash}>{keyHash}</div>
                                    );
                                })}
                            </div>
                        </div>
                        </div>
                        }
                        <div className='field'>
                            <div className="caption">Установить ключи SHA-1 для <b>{selectedGame ? selectedGame.name : '?'}</b>:</div>
                            <div><textarea id="targetLink" className="wide" name="targetLink" maxLength="255"
                                           onChange={this.onSha1KeysChanged.bind(this)} value={selectedGameSha1Keys}
                                style={(selectedGame && selectedGame.key_hashes) ? {height: '25px'} : {}}/></div>
                            <div style={{marginTop: '5px'}}><button onClick={this.onKeyHashesSet.bind(this)} className="common"
                                 style={(selectedGame && selectedGame.key_hashes) ? {height: '24px', padding: '4px 28px'} : {}}
                                 disabled={!selectedGameSha1Keys || selectedGameSha1Keys === ''}>Установить ключи</button></div>
                        </div>

                        <form onSubmit={this.onSubmitGameSettings.bind(this)} className={'thin'}>
                            <ul>
                                {selectField("store_id","Тип приложения", '', stores)}

                                {inputFieldEx('bundle_id',bundleIDCaption)}
                                {inputFieldEx('fb_app_id', 'FB App ID')}
                                {inputFieldEx('fb_secret_key','FB App Secret', 'smallText')}
                                {inputFieldEx('fb_admin_id',"FB Admin Server",'shortField')}
                                {inputFieldEx('fb_share_id',"FB Share ID",'shortField')}
                                {inputFieldEx('appsf_dev_key',"Appsflyer Dev Key", 'smallText')}

                                {selectField("platform_id","Платформа", '', platforms)}

                                {checkboxField('fb_events_on',"Отправка событий в FB")}
                                {checkboxField('naming_support',"Appsflyer нейминг подключен, отправка событий в Appsflyer включена")}
                                {checkboxField('pushexpress_on',"Отправка событий в PUSH.express")}
                                {checkboxField('extra_geo_opened',"Открыть Индию (IN)")}
                                {checkboxField('wv_useragent',"Включить индикатор WebView в user-agent (wv)")}

                                <li key="submit" className="submit">
                                    <div>
                                        <div><button className="common">{gs_executing ? <span className='btnLoadIcon'></span> : 'Сохранить настройки'}</button></div>
                                        {this.state.gs_execMsg && <div className="tinyTip holder">
                                            <span className={this.state.gs_execMsg === SUCCESS_SAVE_MSG ? "green" : ""}>
                                                {this.state.gs_execMsg}</span>
                                        </div>}
                                    </div>
                                </li>
                            </ul>

                        </form>
                    </div>
                    }

                    </div>
                    </RestrictedArea>

                    { errors && errors.common &&
                    <div className="error holder"><div className="message">{errors.common}</div></div>
                    }
                    {this.renderRestErrors()}

                </div>
            </div>
            </RestrictedArea>
        );
    }
}



export default ManageGames;
