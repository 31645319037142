import React, { Component } from 'react';
import axios from "axios";
import '../../css/table.css';
import {formatClientName, formatCurrency, formatDateTime, getResponseErrorsList} from "../../util/util";
import {RestrictedArea}from "../../wrappers/RestrictedArea";
import {UserContext, UserGroup} from "../../context/User";
import Select from "react-select";
import BasePage from "../base/BasePage";
import {DepositRequestStatus, Token} from "../../common/commonTypes";
import {CopyToClipboard} from "react-copy-to-clipboard";
import { Tooltip as ReactTooltip } from "react-tooltip";
import CountdownTimer from "../../widgets/CountdownTimer";
import Pages from "../../widgets/Pages";

const UPDATE_INTERVAL = 10000;
let fetchDataTimer = null;

class Deposit extends BasePage {
    state = {
        loading: true,
        amount: 100,
        newRequest: true,
        page: 1,
        nextAutoUpdateTime: 0
    };

    // Fetch the list on first mount
    componentDidMount() {
        this.fetchData();

        setInterval(() => {
            const curTime = Date.now();
            if (curTime >= this.state.nextAutoUpdateTime) {
                this.fetchData(this.state.page, true);
            }
        }, 1000);
    }

    getUser() {
        return this.context;
    }

    async fetchData(page, autoupdate) {
        if (typeof page === 'undefined') page = this.state.page;
        if (autoupdate && this.state.loading) return;
        this.setState({loading: true, nextAutoUpdateTime: Date.now() + UPDATE_INTERVAL});

        try {
            let res = await axios.post('/get_deposit_requests_list', {page});

            if (res.data) {
                let newState = {loading: false, page: page, ...res.data};
                if (!this.state.selectedToken && newState.deposit_tokens && newState.deposit_tokens.length > 0) {
                    newState.selectedToken = { value: newState.deposit_tokens[0].id, label: newState.deposit_tokens[0].name };
                }
                if (newState.deposit_requests_list) {
                    let isProcessingRequests = false;
                    let isPendingRequests = false;
                    for (let r of newState.deposit_requests_list) {
                        if (r.status === DepositRequestStatus.PENDING) {
                            if (r.time_left_to_expire <= 0) {
                                r.status = DepositRequestStatus.EXPIRED;
                            } else {
                                isPendingRequests = true;
                            }
                        } else if (r.status === DepositRequestStatus.PROCESSING) {
                            isProcessingRequests = true;
                        }
                    }
                    if (!isPendingRequests && !isProcessingRequests) {
                        newState.nextAutoUpdateTime = Date.now() + 5 * UPDATE_INTERVAL;
                    } else if (isProcessingRequests) {
                        newState.nextAutoUpdateTime = Date.now() + UPDATE_INTERVAL / 5;
                    }
                }
                this.setState(newState);
            }
        } catch (e) {
            this.setState({loading: false});
        }
    }

    async onSubmit(event) {
        event.preventDefault();

        let amount = parseFloat(this.state.amount);
        if (amount <= 0) {
            this.setState({errors: {amount: 'Укажите сумму'}});
            return;
        }
        if (!this.state.selectedToken || !this.state.selectedToken.value) {
            this.setState({errors: {token: 'Укажите способ оплаты'}});
            return;
        }
        amount = amount.toFixed(2);

        if (!this.busy) {
            try {
                this.busy = true;
                await axios.post('/add_deposit_request', {
                    amount: this.state.amount,
                    token: this.state.selectedToken.value,
                });

                await this.setState({newRequest: false});

                await this.fetchData();
            } catch (err) {
                this.setState({errors: getResponseErrorsList(err)});
            }
            this.busy = false;
        }

    }

    onNewRequest(event) {
        event.preventDefault();
        this.setState({newRequest: true, errors: {}});
    }

    onDepositTypeSelected(depositType) {
        this.setState({ errors: {}, selectedToken: depositType });
    }

    renderPage() {
        const { loading, deposit_requests_list, deposit_tokens, selectedToken, newRequest, page, isLast } = this.state;
        const user = this.getUser();
        let fundOptions = deposit_tokens ? deposit_tokens.map(dt => ({value: dt.id, label: dt.name })) : [];

        const roField = (description, value, valueStyle) => (
            <li className="field">
                <div>{description}</div>
                <div style={valueStyle ? valueStyle : {}}>{value}</div>
            </li>
        );

        const inputField = (fieldName,description) => (
            <li className="field">
                <div><label htmlFor={fieldName}>{description}</label></div>
                <div><input id={fieldName} name={fieldName} type="text" onChange={this.onFieldChanged.bind(this,fieldName)} required value={this.state[fieldName]}/>
                    {this.renderError(fieldName)}
                </div>
            </li>
        );

        return (
            <RestrictedArea allowedTo={UserGroup.SUPERUSER}>
            <div className="page deposit-funds">
                <h1>Пополнение счета</h1>

                <div className="form">
                    {newRequest ?
                    <form onSubmit={this.onSubmit.bind(this)}>
                        <ul>
                            {roField('Текущий баланс:', formatCurrency(user.balance), {fontWeight:'bold'})}

                            {inputField("amount","Пополнить на, $:")}

                            <li className={'field'}><div>Способ оплаты:</div>
                                <div><Select
                                    className="select"
                                    onChange={this.onDepositTypeSelected.bind(this)}
                                    options={fundOptions}
                                    isDisabled={loading}
                                    isSearchable={false}
                                    value={selectedToken}
                                    placeholder={'-- Способ оплаты --'}
                                />
                                    {this.renderError('token')}
                                </div>
                            </li>

                            <li className="submit">
                                <div>
                                    <div><button className="common" disabled={loading}>{
                                        'Перейти к оплате ➡'}</button></div>
                                    <div style={{maxWidth: '200px'}}>{this.renderRestErrors()}</div>
                                </div>

                            </li>
                        </ul>
                    </form>
                    :
                    <div className={'inRow'}>
                        <div><button className="common" disabled={loading} onClick={this.onNewRequest.bind(this)}>{
                            '+ Новая заявка'}</button></div>
                    </div>
                    }
                </div>

                <div className={'inCol'} style={{marginTop: '30px'}}>
                    {deposit_requests_list && deposit_requests_list.map((r,i) => {
                        const amount_requested = formatCurrency(r.amount_requested,8,'');
                        return (
                        <div key={i} className={"request " + DepositRequestStatus.toString(r.status).toLowerCase()}>
                            <div style={{width: '100%'}}>
                                { r.status === DepositRequestStatus.COMPLETED ?
                                    <div style={{marginLeft: "32px"}}>Зачислено: <b>{formatCurrency(r.amount_to_deposit)}</b></div>
                                :
                                    <div style={{marginLeft: "32px"}}>Сумма к зачислению: <b>{formatCurrency(r.amount_to_deposit)}</b></div>
                                }
                                { r.status === DepositRequestStatus.COMPLETED &&
                                    <div className={'smallText'}  style={{marginLeft: "32px", marginBottom: "10px"}}><i>Ваша заявка успешно обработана.</i></div>
                                }
                                { (r.status === DepositRequestStatus.PENDING || r.status === DepositRequestStatus.PROCESSING) &&
                                    <div className={'smallText'}  style={{marginLeft: "32px"}}><i>Время действия заявки:</i> <CountdownTimer key={r.id} duration={r.time_left_to_expire}/></div>
                                }
                                { r.status === DepositRequestStatus.EXPIRED &&
                                    <div className={'smallText'}  style={{marginLeft: "32px", marginBottom: "10px"}}><i>Срок действия заявки истек.</i></div>
                                }

                                { r.status === DepositRequestStatus.PENDING &&
                                    <div style={{marginTop: '12px', marginLeft: "32px"}} className={'tip'}>При переводе средств пожалуйста указывайте точную сумму указанную ниже
                                    и используйте только перевод <b>{r.token === Token.USDT_TRC20 ? 'TRC20 (Tron)' : Token.toString(r.token)}</b></div>}
                                <div className={'inRow field smallerText'}>
                                    { r.status === DepositRequestStatus.COMPLETED ? <div>Оплачено:</div> : <div>К оплате:</div> }
                                    <div>
                                        <div className='tokenFrame inRow leftAlign'>
                                            { r.status === DepositRequestStatus.PENDING && <CopyToClipboard text={amount_requested}>
                                                <button type="button" className="copy-to-clipboard" data-tip="Скопировать" style={{margin: '0'}}/>
                                            </CopyToClipboard>}
                                            <span>{amount_requested}</span>
                                            <span>{r.token === Token.USDT_TRC20 ? 'USDT' : Token.toString(r.token)}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={'inRow field smallerText'}>
                                    <div>На кошелек {Token.toString(r.token)}:</div>
                                    <div>
                                        <div className='tokenFrame inRow leftAlign'>
                                            { r.status === DepositRequestStatus.PENDING && <CopyToClipboard text={r.deposit_wallet_addr}>
                                                <button type="button" className="copy-to-clipboard" data-tip="Скопировать" style={{margin: '0'}}/>
                                            </CopyToClipboard>}
                                            <span>{r.deposit_wallet_addr}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );})}
                    <div className="footer">
                        <Pages style={{margin: '0 auto'}} handler={this.fetchData.bind(this)} enabled={!loading} current={page} isLast={isLast}/>
                    </div>
                </div>

            </div>
            </RestrictedArea>
        );
    }
}
Deposit.contextType = UserContext;

export default Deposit;
