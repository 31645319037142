import React, { Component } from 'react';
import axios from "axios";
import {RestrictedArea}from "../../wrappers/RestrictedArea";
import {UserGroup} from "../../context/User";
import {getResponseErrorsList, pick} from "../../util/util";
import './cabinet.css';
import Pages from "../../widgets/Pages";
import BasePage from "../base/BasePage";
import ModalWindow from "../../widgets/ModalWindow";

class FBTokens extends BasePage {
    state = {
        loading: true,
        page: 1,
        fbTokens: null,
        addTokenFormVisible: false
    };

    componentDidMount() {
        this.fetchData(this.state.page);
    }

    async fetchData(page) {
        this.setState({loading: true});

        let res = await axios.post('/get_fb_tokens', {page: page});

        if (res.data && Array.isArray(res.data.fbTokens))
            this.setState({loading: false, page: page, isLast: res.data.isLast, fbTokens: res.data.fbTokens});
    }

    onAddNewToken() {
        this.showAddTokenForm();
    }
    showAddTokenForm() {
        this.setState({ addTokenFormVisible: true, fb_pixel: '', fb_token: '' });
    };
    hideAddTokenForm() {
        this.setState({ addTokenFormVisible: false });
    };
    async onAddNewTokenConfirm(e) {
        e.preventDefault();

        try {
            await axios.post('/add_fb_token', pick(this.state, ['fb_pixel','fb_token']));
            this.hideAddTokenForm();
            await this.fetchData(1);
        } catch (err) { this.setState({errors: getResponseErrorsList(err)}); }
    }

    onEditToken(fb_pixel, fb_token) {
        this.showEditTokenForm(fb_pixel, fb_token);
    }
    showEditTokenForm(fb_pixel, fb_token) {
        this.setState({ editTokenFormVisible: true, editFBPixel:fb_pixel, fb_pixel, fb_token });
    };
    hideEditTokenForm() {
        this.setState({ editTokenFormVisible: false });
    };
    async onEditTokenConfirm(e) {
        e.preventDefault();

        try {
            await axios.post('/update_fb_token', pick(this.state, ['fb_pixel','fb_token']));
            this.hideEditTokenForm();
            await this.fetchData(1);
        } catch (err) { this.setState({errors: getResponseErrorsList(err)}); }
    }

    renderPage() {
        const { loading, fbTokens, page, isLast, addTokenFormVisible, editTokenFormVisible, editFBPixel } = this.state;

        const valueForField = (fieldName) => {
            return this.state[fieldName];
        };

        const modalWnd = (editMode, isVisible, anchorContainerID, onClose, onConfirm) => (
            <ModalWindow
                isVisible={isVisible}
                onClose={onClose}
                anchorContainerID={anchorContainerID}
            >
                <form className="wide" noValidate>
                    <ul>
                        <li className="field" key={"fb_pixel"}>
                            <div><label htmlFor={"fb_pixel"}>{"Пиксель:"}</label></div>
                            <div><input id={"fb_pixel"} name={"fb_pixel"} type="text" disabled={editMode}
                                        onChange={this.onFieldChanged.bind(this,"fb_pixel")}
                                        required={true} value={valueForField("fb_pixel")}/>
                                {this.renderError("fb_pixel")}
                            </div>
                        </li>
                        <li className="field" key={"fb_token"}>
                            <div><label htmlFor={"fb_token"}>Токен:</label></div>
                            <div><textarea className={"smallText"} id={"fb_token"} name={"fb_token"} style={{height: '60px'}}
                                        onChange={this.onFieldChanged.bind(this,"fb_token")}
                                        required={true} value={valueForField("fb_token")}/>
                                {this.renderError("fb_token")}
                            </div>
                        </li>
                        <li className="submit inCol">
                            <div><button className="common" onClick={onConfirm}>
                                { editMode ? <span>Изменить</span> : <span>Добавить</span>}</button></div>
                        </li>
                        {this.renderError("common")}
                    </ul>
                </form>
            </ModalWindow>
        )

        const modalAddTokenWnd = () => (
            <div className="modal-anchor" id="addTokenFormHolder">
                {modalWnd(false, addTokenFormVisible, "addTokenFormHolder",
                    this.hideAddTokenForm.bind(this), this.onAddNewTokenConfirm.bind(this))}
            </div>
        );

        const modalEditTokenWnd = (i,showWnd) => (
            <div className="modal-anchor" id={"editTokenFormHolder"+i}
                 style={{position: 'absolute', height: 0, bottom: 0, width: '100%', padding: '0'}}>
                {showWnd && modalWnd(true, editTokenFormVisible, "editTokenFormHolder"+i,
                    this.hideEditTokenForm.bind(this), this.onEditTokenConfirm.bind(this))}
            </div>
        );

        return (
            <RestrictedArea allowedTo={UserGroup.USER}>
            <div className="page fb-tokens">
                <h1>FB pixel tokens</h1>
                <div><section className="table-data-nb">
                    <button onClick={this.onAddNewToken.bind(this)} className="common">Добавить пиксель</button>
                </section></div>

                {modalAddTokenWnd()}

                <div style={{marginTop: '30px'}}>
                    <section className="table-data compact">
                        <header>
                            <div className="col" style={{minWidth: '100px','flexBasis':'30%'}}>Пиксель</div>
                            <div className="col" style={{'flexBasis':'50%'}}>Токен</div>
                            <div className="col" style={{minWidth: '50px','flexBasis':'15%'}}></div>
                        </header>
                        {fbTokens && fbTokens.map((t,i) => (
                            <div key={i} className="row" style={{position: 'relative'}}>
                                <div className="col smallText centerAlign"
                                     style={{minWidth: '100px', 'flexBasis':'30%'}}>{t.fb_pixel}</div>
                                <div className="col verySmallText leftAlign" style={{'flexBasis':'50%'}}>{t.fb_token}</div>
                                <div className="col" style={{minWidth: '50px', 'flexBasis':'10%'}}><button type="button" className="edit"
                                    style={{margin: '0 auto'}} onClick={this.onEditToken.bind(this, t.fb_pixel, t.fb_token)}/></div>
                                {modalEditTokenWnd(i, editTokenFormVisible && editFBPixel === t.fb_pixel)}
                            </div>
                        ))}
                    </section>
                    <div className="footer">
                        <Pages style={{margin: '0 auto'}} handler={this.fetchData.bind(this)}
                               enabled={!loading} current={page} isLast={isLast}/>
                    </div>
                </div>

            </div>

            </RestrictedArea>
        );
    }
}



export default FBTokens;
