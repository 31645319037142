import React, { Component } from 'react';
import axios from "axios";
import {RestrictedArea}from "../../wrappers/RestrictedArea";
import {UserContext, UserGroup} from "../../context/User";
import SelectClient from "../../widgets/admin/SelectClient";
import {formatClientName, formatCurrency, formatDateTime, getResponseErrorsList} from "../../util/util";
import './admin.css';
import Select from "react-select";
import Pages from "../../widgets/Pages";
import BasePage from "../base/BasePage";

class DepositFunds extends BasePage {
    state = {
        loading: true,
        loadingClient: false,
        loadingHistory: true,
        selectedClientID: -1,
        depositHistoryPage: 1
    };

    componentDidMount() {
        this.fetchData();
        this.fetchHistory(this.state.depositHistoryPage);
    }

    getUser() {
        return this.context;
    }

    async fetchData() {
        this.setState({loading: true});

        let res = await axios.post('/admin_deposit_getdata', {data: ['clients','deposit_types']});

        if (res.data)
            this.setState({loading: false, clients: res.data.clients, depositTypes: res.data.deposit_types});
    }

    async fetchClientData(clientID) {
        if (clientID === -1) {
            this.setState({gamePerm: []});
            return;
        }

        this.setState({loadingClient: true});

        try {
            let res = await axios.post('/getclientdata', {client_id: clientID, data: ['name', 'contact_person', 'balance']});

            if (res.data)
                this.setState({loadingClient: false, clientData: res.data,
                    depositAmount: '', selectedDepositType: null, confirmed: false, confirmed2: false});
        } catch (err) {
            this.setState({loadingClient: false, errors: getResponseErrorsList(err)});
        }
    }

    async fetchHistory(page) {
        this.setState({loadingHistory: true});

        let res = await axios.post('/admin_get_deposit_history', {page: page});

        if (res.data && Array.isArray(res.data.depositHistory))
            this.setState({loadingHistory: false, depositHistoryPage: page, depositHistoryIsLast: res.data.isLast,
                depositHistory: res.data.depositHistory, walletBalance: res.data.walletBalance, transactionHistory: res.data.transactionHistory});
    }

    onClientSelected(clientID) {
        this.setState({ errors: {}, selectedClientID: clientID });
        this.fetchClientData(clientID);
    }

    onDepositTypeSelected(depositType) {
        this.setState({ errors: {}, selectedDepositType: depositType });
    }

    async onSubmit(event) {
        event.preventDefault();

        let depositAmount = parseFloat(this.state.depositAmount);
        if (depositAmount <= 0) {
            this.setState({errors: {depositAmount: 'Укажите сумму'}, confirmed: false, confirmed2: false});
            return;
        }
        if (!this.state.selectedDepositType || !this.state.selectedDepositType.value) {
            this.setState({errors: {depositType: 'Укажите счет'}, confirmed: false, confirmed2: false});
            return;
        }
        depositAmount = depositAmount.toFixed(2);

        if (!this.state.confirmed) {
            this.setState({confirmed: true, depositAmount: depositAmount});
        } else if (this.state.confirmed && this.state.confirmed2 && !this.busy) {
            try {
                this.busy = true;
                await axios.post('/admin_make_deposit', {
                    client_id: this.state.selectedClientID,
                    deposit_amount: this.state.depositAmount,
                    deposit_type_id: this.state.selectedDepositType.value,
                });

                this.setState({errors: {}, depositAmount: '', selectedDepositType: null, confirmed: false, confirmed2: false});
                this.fetchClientData(this.state.selectedClientID);
                this.fetchHistory(1);
            } catch (err) {
                this.setState({errors: getResponseErrorsList(err)});
            }
            this.busy = false;
        }

    }

    onBack() {
        this.setState({confirmed: false, confirmed2: false});
    }

    renderPage() {
        const { confirmed, confirmed2, depositAmount, loading,
            loadingClient, clients, clientData, selectedClientID, depositTypes, selectedDepositType,
            loadingHistory, depositHistory, depositHistoryPage, depositHistoryIsLast, transactionHistory, walletBalance} = this.state;

        const inputField = (fieldName,description) => (
            <li className="field">
                <div><label htmlFor={fieldName}>{description}</label></div>
                <div><input id={fieldName} name={fieldName} type="text" onChange={this.onFieldChanged.bind(this,fieldName)} required value={this.state[fieldName]}/>
                    {this.renderError(fieldName)}
                </div>
            </li>
        );

        const roField = (description, value, valueStyle) => (
            <li className="field">
                <div>{description}</div>
                <div style={valueStyle ? valueStyle : {}}>{value}</div>
            </li>
        );

        const onConfirmed2 = () => { this.setState({confirmed2: !confirmed2}) };

        let fundOptions = depositTypes ? depositTypes.map(dt => ({value: dt.id, label: dt.name })) : [];

        const isAdmin = this.getUser().hasPermission(UserGroup.ADMIN);

        /*{ errors &&
        <div className="error holder"><div className="message">{errors}</div></div>
        }*/

        return (
            <RestrictedArea allowedTo={UserGroup.PLATFORM_ADMIN}>
            <div className="page deposit-funds">
                <h1>Пополнение счета</h1>

                <div className="form">
                    <SelectClient
                        clients={clients}
                        onChange={this.onClientSelected.bind(this)}
                        isLoading={loading}
                        isDisabled={loadingClient}
                    />

                    { !loading && selectedClientID !== -1 && clientData &&
                    <form onSubmit={this.onSubmit.bind(this)} className={loadingClient ? 'disabled' : ''}>
                            <h2>{formatClientName(clientData)}: </h2>
                            <ul>
                                {roField('Текущий баланс:', formatCurrency(clientData.balance), {fontWeight:'bold'})}

                                {!confirmed ?
                                inputField("depositAmount","Пополнить на, $:")
                                :
                                roField("Пополняем счет на:", '+' + formatCurrency(depositAmount), {fontWeight: 'bold', color: '#318d31'})
                                }

                                {!confirmed &&
                                <li className={'field'}><div>Средства поступили на:</div>
                                    <div><Select
                                        className="select"
                                        onChange={this.onDepositTypeSelected.bind(this)}
                                        options={fundOptions}
                                        isDisabled={loadingClient}
                                        value={selectedDepositType}
                                        placeholder={'-- Счет --'}
                                    />
                                    {this.renderError('depositType')}
                                    </div>
                                </li>
                                }

                                {confirmed &&
                                <li className={'checkbox'}  onClick={onConfirmed2}>
                                    <div><input type="checkbox"
                                           checked={confirmed2}
                                                     disabled={loadingClient}/></div>
                                    <div>Я проверил и подтверждаю что пользователь уже перевел средства на счет <b>{ selectedDepositType.label }</b></div>
                                </li>
                                }

                                <li className="submit">
                                    <div>
                                        {confirmed &&
                                        <div><button className="common" disabled={loadingClient} onClick={this.onBack.bind(this)}>⬅ Назад</button></div>
                                        }
                                    </div>
                                    <div>
                                        <div><button className="common" disabled={loadingClient || (confirmed && !confirmed2)}>{
                                            confirmed ? '✔ Пополнить' : 'Пополнить ➡'}</button></div>
                                        {this.renderRestErrors()}
                                    </div>
                                </li>
                            </ul>
                    </form>
                    }

                </div>

                <div style={{marginTop: '30px'}}>
                    {isAdmin ? <h2>История пополнений</h2> : <h2>Недавние пополнения</h2>}

                    <section className="table-data compact">
                        <header>
                            <div className="col" style={{flexBasis: "13%"}}>Время</div>
                            <div className="col" style={{flexBasis: "11%"}}>Клиент</div>
                            <div className="col">Сумма</div>
                            <div className="col">Счет</div>
                            <div className="col">Платформа</div>
                            <div className="col">Админ</div>
                        </header>
                        {depositHistory && depositHistory.map((hist,i) => (
                            <div key={i} className="row">
                                <div className="col smallText" style={{flexBasis: "13%"}}>{formatDateTime(hist.time)}</div>
                                <div className="col smallerText" style={{flexBasis: "11%"}}>{formatClientName(hist)}</div>
                                <div className="col">{formatCurrency(hist.amount)}</div>
                                <div className="col">{hist.depositType}</div>
                                <div className="col">{hist.platform}</div>
                                <div className="col smallerText">{hist.admin}</div>
                            </div>
                        ))}
                    </section>
                    <div className="footer">
                        <Pages style={{margin: '0 auto'}} handler={this.fetchHistory.bind(this)} enabled={!loadingHistory} current={depositHistoryPage} isLast={depositHistoryIsLast}/>
                    </div>
                </div>

                {isAdmin && <div style={{marginTop: '40px'}}>
                    <div className="table-data-nb" style={{width: '70%', textAlign: 'left'}}>
                        Баланс кошелька: <span className={'green'}><b>{walletBalance}</b> USDT</span>
                    </div>

                    <section className="table-data compact" style={{width: '70%'}}>
                        <header>
                            <div className="col" style={{flexBasis:'24%'}}><div className="smallText">Время</div></div>
                            <div className="col"><div className="smallText">Сумма</div></div>
                            <div className="col" style={{flexBasis:'42%'}}><div className="smallText">Transaction ID</div></div>
                        </header>
                        {transactionHistory && transactionHistory.map((tr,i) => (
                            <div key={i} className="row">
                                <div className="col" style={{flexBasis:'24%'}}><div className="smallText">{formatDateTime(tr.time)}</div></div>
                                <div className="col"><div className={'smallText ' + (tr.value>0 ? 'green' : 'red') }>
                                    {tr.value>0 ? '+' : ''}{tr.value}</div></div>
                                <div className="col" style={{flexBasis:'42%'}}><div className="veryVerySmallText">{tr.transaction_id}</div></div>
                            </div>
                        ))}
                    </section>
                </div>}

            </div>
            </RestrictedArea>
        );
    }
}
DepositFunds.contextType = UserContext;


export default DepositFunds;
