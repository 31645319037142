import React, { Component } from 'react';
import axios from "axios";
import '../../css/table.css';
import {formatDateTime} from "../../util/util";
import {RestrictedArea}from "../../wrappers/RestrictedArea";
import {UserContext, UserGroup} from "../../context/User";
import {CopyToClipboard} from "react-copy-to-clipboard";
import { Tooltip as ReactTooltip } from "react-tooltip";


class TelegramSettings extends Component {
    state = {
        loading: true,
        authorizedAccounts: [],
        telegramAuthToken: ''
    };

    // Fetch the list on first mount
    componentDidMount() {
        this.fetchData();
    }

    getUser() {
        return this.context;
    }

    async fetchData() {
        this.setState({loading: true});

        let res = await axios.post('/telegram_get_settings');

        if (res.data) {
            this.setState({loading: false, ...res.data});
        }
    }

    async onRenewToken(e) {
        try {
            this.setState({loading: true, telegramAuthToken: ''});
            let res = await axios.post('/telegram_renew_auth_token');

            if (res.data) {
                this.setState({loading: false, ...res.data});
            }
        } catch (err) {
        }
    }

    render() {
        const { loading, telegramAuthToken, authorizedAccounts } = this.state;

        return (
            <RestrictedArea allowedTo={UserGroup.USER}>
            <div className="page">
                <h1>Telegram Bot</h1>
                <div className="info center">
                    <p>
                        У Вас есть возможность подключить телеграм бот чтобы напрямую с него:
                        <ul className='points'>
                            <li>добавлять Facebook кабы в приложение</li>
                            <li>получать информацию о доступных прилах</li>
                        </ul>
                    </p><p>
                        Чтобы подключить бот: <br/>
                        <ol><li>откройте бот в телеграм<br/><b><a className="light" href="tg://resolve?domain=da_apps_bot">@da_apps_bot</a></b></li>
                            <li style={{marginTop: "4px"}}>введите токен авторизации<br/>
                            {loading ? <div className="loading"/> :
                            <div className='readOnlyFrame tokenFrame smallText'>
                                <CopyToClipboard text={telegramAuthToken}>
                                    <button type="button" className="copy-to-clipboard" data-tip="Скопировать" data-for={'tooltip_telegram_auth'}/>
                                </CopyToClipboard>
                                <span>{telegramAuthToken}</span>
                                <ReactTooltip id={'tooltip_telegram_auth'} delayShow={100} type="light" effect="solid" />
                            </div>
                            }
                            </li>
                        </ol>
                    </p>

                    <p style={{width: "60%"}}>
                        {authorizedAccounts.length > 0 ?
                            <div>Подключенные к боту акаунты:
                                <div className='smallTable' style={{margin: "10px 30px 12px"}}>
                                    {authorizedAccounts.map((account, i) =>
                                        <div key={i}><div className='header'>{`${formatDateTime(account.time)}`}</div>
                                            <div className="itemFill">{`${account.tg_first_name} (@${account.tg_username})`}</div></div>
                                    )}
                                </div>
                                <button style={{margin: "0 auto"}} onClick={this.onRenewToken.bind(this)} className="common small2">Сбросить авторизации и обновить токен</button>
                            </div>
                        :
                            <button style={{margin: "5px 45px"}} onClick={this.onRenewToken.bind(this)} className="common small2">Обновить токен</button>
                        }
                    </p>
                </div>
            </div>
            </RestrictedArea>
        );
    }
}
TelegramSettings.contextType = UserContext;

export default TelegramSettings;
