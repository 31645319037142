import React from 'react';
import {NavLink, Outlet, Route,} from "react-router-dom";
import {UserGroup} from "../../context/User";
import {RestrictedArea}from "../../wrappers/RestrictedArea";
import Finances from "./Finances";
import Referrals from "./Referrals";
import GameStats from "./GameStats";
import CompanyStats from "./CompanyStats";
import FBSettings from "./FBSettings";
import TelegramSettings from "./TelegramSettings";
import Deposit from "./Deposit";
import FBTokens from "./FBTokens";

const cabinetNavMenu = [
    { accessLevel: UserGroup.SUPERUSER, path: 'companystats', icon: 'bar-chart', title: 'Статистика компании', element: <CompanyStats/> },
    { accessLevel: UserGroup.SUPERUSER, path: 'financelog', icon: 'coin', title: 'Финансовая история', element: <Finances/> },
    { accessLevel: UserGroup.SUPERUSER, path: 'gamestats', icon: 'bar-chart', title: 'Статистика по прилкам', element: <GameStats/> },
    { accessLevel: UserGroup.USER, path: 'fbsettings', icon: 'fb', title: 'FB кабинеты', element: <FBSettings/> },
    /*{ accessLevel: UserGroup.USER, path: 'fbtokens', icon: 'fbpixel', title: 'FB pixel tokens', element: <FBTokens/> },*/
    { accessLevel: UserGroup.USER, path: 'telegram', icon: 'tg', title: 'Телеграм бот', element: <TelegramSettings/> },
    { accessLevel: UserGroup.SUPERUSER, path: 'referrals', icon: 'user', title: 'Реферальная программа', element: <Referrals/> },
    { accessLevel: UserGroup.SUPERUSER, path: 'deposit', icon: 'money-with-wings', title: 'Пополнить счет', element: <Deposit/> },
];

let CabinetMenu = () => (
    <div className="right-menu-page">
        <div className="menu">
            <div className="menu-list">
                {cabinetNavMenu.map(menuItem =>
                    <RestrictedArea key={menuItem.path} allowedTo={menuItem.accessLevel}>
                        <NavLink to={menuItem.path} activeClassName="active" key={menuItem.path}>{menuItem.icon &&
                        <div className={'icon'} style={{backgroundImage: `url(/icons/${menuItem.icon}.png`}}/>
                        }
                            <div>{menuItem.title}</div>
                        </NavLink>
                    </RestrictedArea>
                )}
            </div>
        </div>
        <Outlet/>
    </div>
);

const CabinetRoutes = () => <Route path='/cabinet/*' element={<CabinetMenu/>}>{
    cabinetNavMenu.map(menuItem =>
        <Route key={menuItem.path} path={menuItem.path} element={ menuItem.element }/>
    )
}</Route>;

export {CabinetRoutes};
