import React, { Component } from 'react';
import axios from "axios";
import {RestrictedArea}from "../../wrappers/RestrictedArea";
import {UserContext, UserGroup} from "../../context/User";
import {formatClientName, formatCurrency, formatDateTime, getResponseErrorsList} from "../../util/util";
import Select from "react-select";
import BasePage from "../base/BasePage";
import SelectClient from "../../widgets/admin/SelectClient";
import SelectUser from "../../widgets/admin/SelectUser";
import Pages from "../../widgets/Pages";

class WalletExplorer extends BasePage {
    state = {
        loading: true,
        loadingWallet: true,
        selectedWalletID: -1,
        page: 1
    };

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        this.setState({loading: true});

        let res = await axios.post('/wallet_getAllWallets');

        if (res.data) {
            const walletID = (res.data.wallets && res.data.wallets.length > 0) ? res.data.wallets[0].id : -1;
            this.setState({
                loading: false, wallets: res.data.wallets,
                selectedWalletID: walletID
            });

            if (walletID > 0) await this.fetchWalletData(walletID, 1);
        }
    }

    async fetchWalletData(walletID, page) {
        this.setState({loadingWallet: true, page: page});

        let res = await axios.post('/wallet_getData', {id: walletID, page: page});

        if (res.data)
            this.setState({loadingWallet: false, walletData: res.data});
    }

    onWalletSelected(walletOpt) {
        this.setState({ errors: {}, selectedWalletID: walletOpt.value, page: 1 });
        this.fetchWalletData(walletOpt.value, 1);
    }


    renderPage() {
        const { loading, loadingWallet, wallets, selectedWalletID, walletData, page} = this.state;

        /*{ errors &&
        <div className="error holder"><div className="message">{errors}</div></div>
        }*/
        let walletsOpt = wallets ? wallets.map(w => ({
            value: w.id,
            label: w.name
        }) ) : [];
        let walletsSel = walletsOpt.filter(w => w.value === selectedWalletID);
        walletsSel = (walletsSel.length>0) ? walletsSel[0] : null;

        return (
            <div className="page deposit-funds">
                <h1>Кошелек</h1>
                <div className={'center-section'}>
                    <Select
                        className={'select'}
                        options={walletsOpt}
                        value={walletsSel}
                        onChange={this.onWalletSelected.bind(this)}
                        isLoading={loading}
                        isDisabled={loadingWallet}
                    />
                </div>

                {walletData &&
                <div style={{marginTop: '40px'}}>
                    <div className="table-data-nb" style={{width: '70%', textAlign: 'left'}}>
                        Кошелек <b>{walletData.name}</b>: <div className={'readOnlyFrame tokenFrame'}>{walletData.addr}</div>
                    </div>
                    <div className="table-data-nb" style={{width: '70%', textAlign: 'left'}}>
                        Баланс: <span className={'green'}><b>{walletData.balance}</b> USDT</span>
                    </div>

                    <section className="table-data compact" style={{width: '70%'}}>
                        <header>
                            <div className="col" style={{flexBasis:'24%'}}><div className="smallText">Время</div></div>
                            <div className="col"><div className="smallText">Сумма</div></div>
                            <div className="col" style={{flexBasis:'42%'}}><div className="smallText">Transaction ID</div></div>
                        </header>
                        {walletData.transactions && walletData.transactions.map((tr,i) => (
                            <div key={i} className="row">
                                <div className="col" style={{flexBasis:'24%'}}><div className="smallText">{formatDateTime(tr.time)}</div></div>
                                <div className="col"><div className={'smallText ' + (tr.value>0 ? (tr.value>=1 ? 'green' : 'gray') : 'red') }>
                                    {tr.value>0 ? '+' : ''}{tr.value}</div></div>
                                <div className="col" style={{flexBasis:'42%'}}><div className="veryVerySmallText">
                                    <a className={'soft'} href={`https://tronscan.org/#/transaction/${tr.transaction_id}`} target='_blank'>{tr.transaction_id}</a></div></div>
                            </div>
                        ))}
                    </section>
                    <div className="footer">
                        <Pages style={{margin: '0 auto'}} handler={this.fetchWalletData.bind(this, selectedWalletID)}
                               enabled={!loadingWallet} current={page} isLast={walletData.isLastTransactions}/>
                    </div>
                </div>

                }

            </div>
        );
    }
}


export default WalletExplorer;
